#cookiesPage {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9997;
  overflow: hidden;
}
#cookieConsent {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 50%;
  max-width: 750px;
  height: auto;
  border: 2px solid var(--accent-color);
  background: #fff;
  color: #000;
  font-size: 1rem;
  padding: 2.5% 3.5%;
  box-sizing: border-box;
  margin: 0 auto;
  z-index: 9998;
  text-align: center;
  transition: 0.5s;
}
#cookieConsent h3 {
  font-size: 2rem;
  margin-bottom: 20px;
}
#cookieConsent .content {
  width: 100%;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
}
#cookieConsent .content p {
  margin: 10px auto;
  line-height: 1.7;
}
#cookieConsent .content a {
  color: var(--accent-color);
  text-decoration: underline;
}
#cookieConsent .content a:hover {
  text-decoration: none;
}
#cookiePreferences a.close-cookies,
#cookieConsent a.close-cookies {
  position: absolute;
  top: 12px;
  right: 24px;
  font-size: 1rem;
  color: #999;
  text-decoration: none;
  cursor: pointer;
}
#cookiePreferences .content .buttons,
#cookieConsent .content .buttons {
  display: block;
  margin-top: 40px;
}
#cookieConsent .content .buttons a {
  display: inline-block;
  padding: 8px 16px;
  min-width: 220px;
  box-sizing: border-box;
  background: var(--accent-color);
  border: 2px solid var(--accent-color);
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
  font-size: 1.05rem;
  font-weight: bold;
  cursor: pointer;
}
#cookieConsent .content .buttons a.preferences {
  background: #fff;
  color: var(--accent-color);
  border: 2px solid var(--accent-color);
  margin-right: 20px;
}
#cookiePreferences {
  display: none;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 50%;
  max-width: 750px;
  height: auto;
  border: 2px solid var(--accent-color);
  background: #fff;
  color: #000;
  font-size: 1rem;
  padding: 2.5% 3.5%;
  box-sizing: border-box;
  margin: 0 auto;
  z-index: 9998;
  text-align: center;
  transition: 0.5s;
}
#cookiePreferences h3 {
  font-size: 2rem;
  margin-bottom: 20px;
}
#cookiePreferences .content {
  width: 100%;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
}
#cookiePreferences .content p {
  margin: 10px auto;
  line-height: 1.7;
}
#cookiePreferences .content a {
  color: var(--accent-color);
  text-decoration: underline;
}
#cookiePreferences .content .buttons a,
#cookiePreferences .content .buttons button {
  display: inline-block;
  padding: 8px 16px;
  min-width: 220px;
  box-sizing: border-box;
  background: var(--accent-color);
  border: 2px solid var(--accent-color);
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
  font-size: 1.05rem;
  font-weight: bold;
  cursor: pointer;
}
#cookiePreferences .content .buttons button {
  margin-right: 20px;
}
#cookiePreferences .content h4 {
  font-size: 1.25rem;
  margin-top: 25px;
  text-transform: uppercase;
  text-align: left;
}
#cookiePreferences .content .cookies-table {
  width: 100%;
  margin: 5px auto;
  position: relative;
}
#cookiePreferences .content .cookies-table .cookie-row {
  display: flex;
  margin: 0 auto;
  padding: 12px 18px;
  box-sizing: border-box;
  align-items: center;
  flex-wrap: wrap;
  gap: 2%;
  border: 1px solid #999;
  text-align: left;
  font-size: 1.1rem;
}
#cookiePreferences .content .cookies-table a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  display: block;
}
#cookiePreferences .content .cookies-table .cookie-row .cookie-type {
  color: #000;
  width: 70%;
  flex: 0 1 calc(70%);
  margin: 0;
  padding: 0;
  font-weight: 800;
}
#cookiePreferences .content .cookies-table .cookie-row .cookie-option {
  color: var(--accent-color);
  font-weight: bold;
  width: 28%;
  flex: 0 1 calc(28%);
  text-align: right;
}
#cookiePreferences .content .cookies-table .cookie-row .cookie-option i {
  color: #000;
  margin-left: 10px;
  font-size: 1.1rem;
  transition: 0.5s;
  position: relative;
  top: 4px;
}
#cookiePreferences .content .cookies-table .cookie-row .cookie-option i.expanded {
  transform: rotate(90deg);
}
#cookiePreferences .content .cookies-table .cookie-info {
  display: none;
  font-size: 0.9rem;
  margin-top: 15px;
}
@media (max-width: 767px) {
  #cookiePreferences,
  #cookieConsent {
    width: 95%;
    padding: 6% 5%;
    overflow-y: auto;
  }
  #cookiePreferences h3,
  #cookieConsent h3 {
    font-size: 1.4rem;
  }
  #cookiePreferences .content h4 {
    font-size: 1rem;
    margin-top: 20px;
  }
  #cookiePreferences .content .buttons a,
  #cookieConsent .content .buttons a,
  #cookiePreferences .content .buttons button,
  #cookieConsent .content .buttons button {
    display: block;
    width: 100%;
    margin: 10px 0;
    font-size: 0.9rem;
  }
  #cookiePreferences .content .cookies-table .cookie-row {
    padding: 8px 14px;
    font-size: 0.95rem;
  }
  #cookiePreferences .content .cookies-table .cookie-row .cookie-type {
    width: 65%;
    flex: 0 1 calc(65%);
  }
  #cookiePreferences .content .cookies-table .cookie-row .cookie-option {
    width: 32%;
    flex: 0 1 calc(32%);
    font-size: 0.9rem;
  }
  #cookiePreferences .content .cookies-table .cookie-row .cookie-option i {
    margin-left: 6px;
    font-size: 1rem;
    top: 6px;
  }
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 28px;
  top: 4px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input.cookie-check:checked + .slider {
  background-color: var(--accent-color);
}
input.cookie-check:focus + .slider {
  box-shadow: 0 0 1px var(--accent-color);
}
input.cookie-check:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.setCookies {
  cursor: pointer;
  text-decoration: none !important;
  background: var(--accent-color);
  padding: 12px 24px;
  box-sizing: border-box;
  color: #fff;
}
.table-resp table {
  width: 100%;
  margin: 20px auto;
  padding: 0;
  border: 0;
}
.table-resp table th {
  padding: 16px 14px;
  box-sizing: border-box;
  background: var(--accent-color);
  color: #fff;
  font-size: 1.1rem;
  border-bottom: 2px solid #fff;
}
.table-resp table td {
  padding: 12px 14px;
  box-sizing: border-box;
  color: #333;
  border-bottom: 1px solid #efefef;
}
@media (max-width: 767px) {
  .table-resp {
    overflow-x: auto;
  }
  .table-resp table {
    min-width: 1000px;
    overflow-x: auto;
    display: block;
  }
}
