.navbar {
  padding: 12px 0;
}
.navbar-brand img {
  height: 100px;
  width: auto;
}
.cta-contact-item a {
  color: var(--text-color);
  text-decoration: underline;
}
.provide-item-content h3 {
  line-height: 1.5;
  position: relative;
}
.we-provide-item {
  padding: 24px 0;
  box-sizing: border-box;
  transition: 0.3s;
  height: auto;
  position: relative;
  background: #f2f2f2;
  border-radius: 20px;
  margin-bottom: 15px;
}
.we-provide-item:hover {
  background: rgba(2, 66, 93, 0.15);
}
.provide-item-no {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
@media (max-width: 767px) {
  .provide-item-no {
    right: 0;
  }
}
.we-provide-item .provide-item-no h3 {
  transition: 0.5s;
}
.we-provide-item .provide-item-no h3 i {
  transition: 0.5s;
}
.we-provide-item .provide-item-no h3 i.active {
  transform: rotate(-90deg);
}
.we-provide-item:hover .provide-item-no h3 {
  background-color: var(--primary-color);
}
.provide-item-content h3 {
  margin-bottom: 0;
}
.contact-items-row {
  margin-bottom: 40px !important;
}
.contact-items-row .col-lg-3 {
  align-self: stretch;
}
.contact-box {
  background-color: #f2f2f2;
  transition: 0.4s;
  border: 1px solid var(--divider-color);
  border-radius: 30px;
  padding: 16px;
  height: 100%;
}
@media (max-width: 1000px) {
  .contact-items-row .col-lg-3 {
    margin-bottom: 20px;
  }
}
.contact-box strong {
  color: var(--primary-color);
  font-size: 1.1rem;
  margin-bottom: 15px;
}
.contact-box:hover {
  background-color: #dedede;
}
#slideshow {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
#slideshow .slide {
  position: absolute !important;
  width: 100%;
  height: 100%;
  background-position: center center !important;
}
.photo-stripe {
  width: 100%;
  position: relative;
  height: 500px;
  margin: 0;
  padding: 0;
}
@media (max-width: 1400px) {
  .photo-stripe {
    height: 400px;
  }
}
@media (max-width: 1000px) {
  .photo-stripe {
    height: 300px;
  }
}
.photo-stripe {
  background-attachment: fixed !important;
}
@media (max-width: 767px) {
  .photo-stripe {
    background-attachment: local !important;
  }
}
@media (hover: none) and (pointer: coarse) {
  .photo-stripe {
    background-attachment: local !important;
  }
}
#pojistovny {
  background: var(--primary-color);
  color: #fff;
  padding: 3% 0 2.5% 0;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  text-align: center;
}
#pojistovny h2 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 40px;
}
#pojistovny img {
  height: 45px;
  width: auto;
  margin: 20px 25px;
  display: inline-block;
}
@media (max-width: 1000px) {
  #pojistovny img #pojistovny img {
    height: 32px;
  }
}
.contact-form {
  background: #f2f2f2;
  border-radius: 20px;
  width: 100%;
  padding: 3% 5%;
  box-sizing: border-box;
  margin: 0 auto 60px auto;
}
.contact-form input[type="text"],
.contact-form textarea,
.contact-form input[type="email"],
.contact-form select {
  border-radius: 10px;
  width: 100%;
  background: #fff;
  font-family: Figtree, serif !important;
  font-size: 1rem;
  border: 1px solid var(--divider-color);
  padding: 12px 8px;
  outline: none;
  transition: 0.4s;
}
.contact-form select {
  padding: 14px 8px;
}
.contact-form textarea {
  resize: none;
}
.contact-form select:hover,
.contact-form select:focus,
.contact-form textarea:hover,
.contact-form textarea:focus,
.contact-form input[type="text"]:hover,
.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form input[type="email"]:hover {
  background: rgba(2, 66, 93, 0.15);
}
select option {
  background: rgba(2, 66, 93, 0.15);
  color: #000;
  font-family: Figtree, serif !important;
}
.contact-form input[type="text"]::placeholder,
.contact-form textarea::placeholder,
.contact-form input[type="email"]::placeholder,
.contact-form select::placeholder {
  color: #000;
  opacity: 1;
}
.file-upload {
  width: 100%;
  border: 2px dashed #ccc;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  position: relative;
}
.file-upload:hover {
  border-color: var(--accent-color);
}
.file-upload input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.upload-label .icon {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 8px;
}
.upload-label .text {
  font-size: 1.1rem;
}
.file-count {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #666;
}
@media (min-width: 768px) {
  .about-us-content p {
    font-size: 1.1rem;
  }
  .about-us-content strong {
    font-size: 1.2rem;
  }
}
.vision-mission-content {
  max-width: 800px;
  margin: 0 auto;
}
.vision-mission-content p {
  font-size: 1.1rem;
}
@media (max-width: 767px) {
  .vision-mission-content p {
    font-size: 1rem;
  }
}
.preferences-container {
  margin-top: 50px;
}
.preferences {
  max-width: 1000px;
  margin: 20px auto;
  list-style: none;
  text-align: center;
}
@media (max-width: 1200px) {
  .preferences {
    text-align: left;
  }
}
.preferences li {
  margin: 5px auto;
  font-size: 1.15rem;
  list-style: none;
  padding: 15px 5%;
  box-sizing: border-box;
  transition: 0.4s;
}
@media (max-width: 767px) {
  .preferences li {
    font-size: 1.05rem;
    padding: 12px 2%;
  }
}
.preferences li i {
  display: inline-block;
  color: var(--primary-color);
  font-size: 1.25rem;
  margin-right: 7px;
}
@media (max-width: 767px) {
  .preferences li i {
    font-size: 1.15rem;
  }
}
.preferences li:hover {
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  background: rgba(2, 66, 93, 0.15);
}
@media (max-width: 1250px) {
  .page-about-us .align-items-center {
    align-items: flex-start !important;
  }
}
