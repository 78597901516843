body {
  scroll-behavior: smooth;
  font-family: "Figtree", serif !important;
}
.we-provide-item strong {
  color: var(--primary-color) !important;
  font-weight: 700;
}
@media (max-width: 1200px) {
  .we-provide-item {
    text-align: left !important;
  }
  .we-provide-item.price {
    text-align: center !important;
  }
}
.mobydyk {
  display: inline-block;
  mask: url("../../img/mobydyk-icon.svg");
  -webkit-mask: url("../../img/mobydyk-icon.svg");
  mask-position: center;
  -webkit-mask-position: center;
  mask-size: cover;
  -webkit-mask-size: cover;
  fill: #fff;
  height: 22.4px;
  width: 30.4px;
  margin-left: 7px;
  margin-bottom: -4px;
  background: #0f0f0f;
}
.clr {
  clear: both;
}
.error-wrapper {
  max-width: 60%;
  text-align: center;
  padding: 10% 0;
  margin: 0 auto;
  font-size: 1.2rem;
}
.error-wrapper a {
  color: #000;
}
.error-wrapper i {
  font-size: 5rem;
  display: block;
}
@media (max-width: 1000px) {
  .error-wrapper {
    max-width: 90%;
  }
}
@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}
.Image__Lazy--load img {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}
.Image__Lazy--load {
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(10px);
  transition: 0.4s;
}
.Image__Lazy--load::before {
  content: "";
  position: absolute;
  inset: 0;
  opacity: 0;
  animation: pulse 4s infinite;
}
.Image__Lazy--load.loaded {
  filter: none;
}
.Image__Lazy--load.loaded::before {
  animation: none;
  content: none;
}
.Image__Lazy--load.loaded img {
  opacity: 1;
}
@media (max-width: 1200px) {
  .hero-content-footer {
    display: none !important;
  }
}
@media (min-width: 1001px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
}
@media (max-width: 1000px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}
.hero {
  padding: 0;
}
.main-page {
  width: 100%;
  display: block;
  margin: 0 auto;
  position: relative;
  padding: 0;
  align-items: center;
}
.main-page .image {
  background: url('../../img/full-bg.png') no-repeat center left;
  background-size: cover;
  width: 100%;
  margin: 0;
  height: 75vh;
  min-height: 600px;
  max-height: 850px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1600px) {
  .main-page .image {
    min-height: 500px;
    height: 70vh;
    max-height: 600px;
  }
}
@media (max-width: 1450px) {
  .main-page .image {
    min-height: 500px;
    height: 70vh;
    max-height: 560px;
  }
}
@media (max-width: 1367px) {
  .main-page .image {
    min-height: 450px;
    height: 70vh;
    max-height: 525px;
  }
}
@media (max-width: 1275px) {
  .main-page .image {
    min-height: 450px;
    height: 70vh;
    max-height: 480px;
  }
}
@media (max-width: 1200px) {
  .main-page .image {
    min-height: 400px;
    height: 70vh;
    max-height: 460px;
  }
}
@media (max-width: 1100px) {
  .main-page .image {
    min-height: 400px;
    height: 70vh;
    max-height: 400px;
  }
}
.main-page .content {
  width: 45%;
  flex: 0 1 calc(45%);
  padding-right: 7.5%;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}
@media (max-width: 1367px) {
  .main-page .content {
    width: 40%;
    padding-right: 5%;
  }
}
@media (max-width: 1600px) {
  .section-title h1 {
    font-size: 48px;
  }
  .about-us-content .section-title h1,
  .vision-mission .section-title h1 {
    font-size: 42px;
  }
}
@media (max-width: 1367px) {
  .section-title h1 {
    font-size: 44px;
  }
  .about-us-content .section-title h1,
  .vision-mission .section-title h1 {
    font-size: 38px;
  }
}
@media (max-width: 1200px) {
  .section-title h1 {
    font-size: 40px;
  }
  .about-us-content .section-title h1,
  .vision-mission .section-title h1 {
    font-size: 38px;
  }
}
@media (max-width: 1000px) {
  .main-page {
    display: block;
  }
  .main-page .image {
    width: 100%;
    flex: none;
    min-height: 1000px;
    height: auto;
    background: url('../../img/bg-mobile.png') no-repeat bottom center;
    background-size: cover;
  }
  .main-page .content {
    width: 100%;
    flex: none;
    padding: 3% 5%;
    box-sizing: border-box;
    text-align: center;
    right: 0;
    left: 0;
    top: 50px;
    transform: none;
  }
  .hero-content-footer {
    display: block;
  }
  .hero-content-footer .hero-footer-box {
    width: 100%;
    display: block;
    margin: 20px auto;
    text-align: center;
  }
  .hero-footer-box .icon-box {
    display: flex !important;
    margin: 0 auto;
  }
  .hero-footer-box .hero-footer-box-content {
    text-align: center;
    margin: 15px auto;
  }
  .hero-footer-box .hero-footer-box-content p {
    text-align: center;
  }
}
@media (max-width: 900px) {
  .main-page .image {
    min-height: 950px;
  }
}
@media (max-width: 750px) {
  .main-page .image {
    min-height: 800px;
  }
}
@media (max-width: 650px) {
  .main-page .image {
    min-height: 800px;
  }
}
@media (max-width: 500px) {
  .main-page .image {
    min-height: 650px;
  }
}
@media (max-width: 767px) {
  .cb-cursor {
    display: none !important;
  }
}
